<template>
  <div class="upload-dialog">
    <span class="material-icons close-icon" @click="onClose"> close </span>
    <template v-if="!dialogState.showError && !dialogState.showProcessing">
      <div class="dialog-title">
        <span v-if="dialogState.showCropper">{{ $t("tune_the_photo") }}</span>
        <span v-else-if="dialogState.showUpload">{{
          $t("upload_photo_terms_dialog_title")
        }}</span>
        <span v-else>{{ $t("upload_photo") }}</span>
      </div>
      <div class="dialog-text" v-if="showUploadHint">
        <span>{{ $t("upload_photo_text_hint_1") }}</span>
      </div>
    </template>

    <!-- upload Privacy Policy -->
    <template v-if="dialogState.showUpload">
      <div class="dialog-terms">
        <div class="terms-checkbox">
          <mem-checkbox v-model="termsCheckbox1" theme="light" />
          <span @click="termsCheckbox1 = !termsCheckbox1">{{
            $t("upload_photo_terms_dialog_checkbox_1")
          }}</span>
        </div>
        <div class="terms-checkbox">
          <mem-checkbox v-model="termsCheckbox2" theme="light" />
          <span @click="termsCheckbox2 = !termsCheckbox2">{{
            $t("upload_photo_terms_dialog_checkbox_2")
          }}</span>
        </div>
        <i18n
          tag="div"
          class="terms-subline"
          path="upload_photo_terms_dialog_subline"
        >
          <a href="https://www.sportograf.com/en/privacy" target="_blank">
            {{ $t("privacy_policy") }}</a
          >
        </i18n>
        <div class="dialog-buttons">
          <mem-button
            v-if="!isMobile"
            @click="openCamera"
            :disabled="uploadDisabled"
            >{{ $t("upload_photo_terms_dialog_button_text") }}</mem-button
          >
          <!-- mobile capture -->
          <template v-if="isMobile">
            <input
              id="cameraFile"
              ref="cameraPhoto"
              type="file"
              accept="image/*"
              capture="user"
              :disabled="uploadDisabled"
              @change="onPhotoCaptured"
            />
            <label
              for="cameraFile"
              class="mobile-button"
              :class="{ 'is-disabled': uploadDisabled }"
            >
              <span>{{ $t("upload_photo_terms_dialog_button_text") }}</span>
            </label>
          </template>
        </div>
      </div>
    </template>

    <!-- browser capture -->

    <template v-if="dialogState.showCamera">
      <video
        id="video"
        width="400"
        height="400"
        autoplay
        disablepictureinpicture
      />
      <div class="dialog-buttons">
        <div class="camera-button" @click="getPhoto">
          <span class="material-icons"> photo_camera </span>
        </div>
      </div>
    </template>

    <!-- crop -->

    <template v-if="dialogState.showCropper">
      <cropper
        ref="cropper"
        class="cropper"
        image-restriction="stencil"
        :stencil-size="stencilSize"
        :stencil-props="{
          lines: {},
          handlers: {},
          movable: false,
          scalable: false,
        }"
        :transitions="false"
        :debounce="false"
        :default-size="defaultSize"
        :min-width="cropperParams.minWidth"
        :min-height="cropperParams.minHeight"
        :src="image"
        :canvas="{
          minHeight: 0,
          minWidth: 0,
          maxHeight: 800,
          maxWidth: 800,
        }"
        @change="onChangeCropper"
      ></cropper>
      <div class="cropper-controls">
        <div class="slider-control" @click="decrement">
          <span class="material-icons"> remove </span>
        </div>
        <v-slider
          class="cropper-slider"
          v-model="slider"
          dense
          :min="sliderMin"
          :max="sliderMax"
          track-color="#DDDDDD"
          track-fill-color="#000000"
          v-on:input="onSliderChange"
        >
        </v-slider>
        <div class="slider-control" @click="increment">
          <span class="material-icons"> add </span>
        </div>
      </div>
      <div class="dialog-buttons">
        <mem-button @click="getCroppImage">{{ $t("apply") }}</mem-button>
        <div
          class="label-button"
          style="margin-left: 6px"
          v-if="!isMobile"
          @click="retakeFile"
        >
          {{ $t("retake") }}
        </div>
        <template v-if="isMobile">
          <input
            id="cameraFile"
            ref="cameraPhoto"
            type="file"
            accept="image/*"
            capture="user"
            :disabled="uploadDisabled"
            @change="onPhotoCaptured"
          />
          <label for="cameraFile" class="label-button" style="margin-left: 6px">
            <span>{{ $t("retake") }}</span>
          </label>
        </template>
      </div>
    </template>

    <!-- processing -->

    <template v-if="dialogState.showProcessing">
      <div class="file-processing">
        <progress-lines
          :is-loaded="!dialogState.showProcessing"
          :lines-count="6"
          infinite
        ></progress-lines>
        <div class="processing-text">
          {{ $t("upload_selfie_processing") }}
        </div>
      </div>
    </template>

    <!-- errors -->

    <template v-if="dialogState.showError">
      <div class="dialog-error">
        <img
          class="warning-icon"
          src="@/assets/warning_rhombus.svg"
          alt="upload_error"
        />
        <div class="error-message">
          <span v-if="errorTypes.fileFormat">{{
            $t("upload_selfie_wrong_file")
          }}</span>
          <span v-if="errorTypes.server">
            {{ $t(`${errorText}`) }}
          </span>
        </div>
      </div>
      <div class="dialog-buttons">
        <div class="label-button" v-if="!isMobile" @click="retakeFile">
          {{ $t("select_new_photo") }}
        </div>
        <template v-if="isMobile">
          <input
            id="cameraFile"
            ref="cameraPhoto"
            type="file"
            accept="image/*"
            capture="user"
            :disabled="uploadDisabled"
            @change="onPhotoCaptured"
          />
          <label for="cameraFile" class="label-button">
            <span>{{ $t("retake") }}</span>
          </label>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
const ERROR_TYPES = {
  FACE_TOO_SMALL: "upload_selfie_error_face_too_small",
  IMAGE_TOO_SMALL: "upload_selfie_error_image_too_small",
  NO_FACES: "upload_selfie_error_no_faces",
  BAD_SELFIE_POSE: "upload_selfie_error_bad_selfie_pose",
  NO_PRIMARY_FACE: "upload_selfie_error_no_primary_face",
  DEFAULT: "upload_selfie_error_not_available",
};

import { mapActions } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "mem-checkbox": () => import("@/components/base/BaseCheckbox.vue"),
    "progress-lines": () => import("@/components/ProgressLines.vue"),
  },
  props: ["resultId", "onClose", "isFullscreen", "isMobile"],
  data: () => ({
    dialogState: {
      showUpload: true,
      showCamera: false,
      showCropper: false,
      showProcessing: false,
      showError: false,
    },
    errorTypes: {
      fileFormat: false,
      server: false,
    },
    errorText: "",
    stream: null,
    image: "",
    cropperParams: {
      minWidth: 0,
      minHeight: 0,
    },
    slider: 0,
    sliderMin: 0,
    sliderMax: 100,
    zoom: 0,
    croppResult: null,
    isLoaded: false,
    termsCheckbox1: true,
    termsCheckbox2: true,
  }),
  watch: {},
  computed: {
    showUploadHint() {
      return !this.dialogState.showCropper && !this.dialogState.showUpload;
    },
    uploadDisabled() {
      return !this.termsCheckbox1 || !this.termsCheckbox2;
    },
  },
  methods: {
    ...mapActions(["uploadSelfie", "getResult"]),
    defaultSize({ imageSize }) {
      // return {
      //   width: imageSize.width,
      //   height: imageSize.height,
      // };
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: boundaries.width,
        height: boundaries.height,
      };
      // return {
      //   width: Math.min(boundaries.height, boundaries.width) - 48,
      //   height: Math.min(boundaries.height, boundaries.width) - 48,
      // };
    },
    // addFile(event) {
    //   this.$refs.file.files = event.dataTransfer.files;
    //   this.onFileInputChange();
    //   event.currentTarget.classList.remove("drop-shadow");
    // },
    onPhotoCaptured() {
      // Mobile device capture
      let file = this.$refs.cameraPhoto.files[0];
      this.getFile(file);
    },
    onFileInputChange() {
      let file = this.$refs.file.files[0];
      this.getFile(file);
    },
    getFile(file) {
      if (!file) return;
      let fileSize = this.getFileSize(file.size);
      let fileFormat = file.type;
      // file errors
      if (
        (fileFormat !== "image/png" && fileFormat !== "image/jpeg") ||
        fileSize > 15000
      ) {
        this.dialogState.showUpload = false;
        this.dialogState.showError = true;
        this.errorTypes.fileFormat = true;
        return;
      }

      this.dialogState.showUpload = false;
      this.dialogState.showCropper = true;
      this.image = URL.createObjectURL(file);
    },
    getFileSize(val) {
      return Math.floor(val / 1024);
    },
    async getPhoto() {
      let canvas = document.createElement("canvas");
      let video = document.querySelector("#video");
      video.height = video.height * 2;
      video.width = video.width * 2;

      canvas.height = video.height;
      canvas.width = video.width;
      canvas.getContext("2d").drawImage(video, 0, 0, video.width, video.height);

      let image_data_url = canvas.toDataURL("image/png", 1.0);
      this.image = image_data_url;
      await this.turnOffCamera();
      this.dialogState.showCamera = false;
      this.dialogState.showCropper = true;
      canvas.remove();
    },
    async openCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { min: 800, ideal: 1600 },
            height: { min: 800, ideal: 1600 },
          },
          audio: false,
        });
        this.dialogState.showUpload = false;
        this.dialogState.showCropper = false;
        this.$nextTick(() => {
          this.dialogState.showCamera = true;
          // setTimeout - fix for Safari
          setTimeout(() => {
            let video = document.querySelector("#video");
            video.srcObject = this.stream;
          }, 0);
        });
      } catch (e) {
        console.log(e, "Camera is not available");
        alert("Camera is not available");
      }
    },
    async turnOffCamera() {
      try {
        let tracks = await this.stream?.getVideoTracks();
        if (tracks)
          tracks.forEach((track) => {
            track.stop();
          });
        this.dialogState.showCamera = false;
      } catch (e) {
        console.log("Camera is not available", e);
      }
    },
    async retakeFile() {
      this.dialogState.showError = false;
      this.errorTypes.fileFormat = false;
      this.errorTypes.server = false;
      this.errorText = "";

      this.image = "";
      this.slider = 0;
      this.sliderMax = 100;
      this.cropperParams.minWidth = 0;
      this.cropperParams.minHeight = 0;

      await this.openCamera();

      // this.dialogState.showUpload = true;
    },
    async getCroppImage() {
      let { canvas } = this.$refs.cropper.getResult();
      if (!HTMLCanvasElement.prototype.toBlob) {
        this.croppResult = canvas.msToBlob();
        await this.uploadImage();
      } else {
        canvas.toBlob(
          async (b) => {
            this.croppResult = b;
            await this.uploadImage();
          },
          "image/jpeg",
          1
        );
      }
    },
    async uploadImage() {
      if (this.uploading) return;
      const fd = new FormData();
      fd.append("selfie", this.croppResult);
      this.dialogState.showCropper = false;
      this.dialogState.showProcessing = true;
      try {
        await this.uploadSelfie({ image: fd, resultId: this.resultId });
        // await this.getResult(this.resultId);

        this.dialogState.showProcessing = false;
        this.onClose();
      } catch (error) {
        console.log(error, "Upload error");
        this.dialogState.showProcessing = false;
        this.dialogState.showError = true;
        this.errorTypes.server = true;
        this.errorText = ERROR_TYPES[error] || ERROR_TYPES["DEFAULT"];
      }
    },
    onChangeCropper() {
      const cropper = this.$refs.cropper;
      if (!cropper) return;
      const { coordinates, imageSize } = cropper;

      // set cropper min/max size
      this.cropperParams.minWidth = imageSize.width * 0.1;
      this.cropperParams.minHeight = imageSize.height * 0.1;

      if (
        imageSize.width / imageSize.height >
        coordinates.width / coordinates.height
      ) {
        // Determine the position of slider bullet
        // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
        this.zoom =
          (cropper.imageSize.height - cropper.coordinates.height) /
          (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
      } else {
        this.zoom =
          (cropper.imageSize.width - cropper.coordinates.width) /
          (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
      }

      // set max value of slider, based on max available zoom
      if (
        coordinates.width === cropper.sizeRestrictions.minWidth ||
        coordinates.height === cropper.sizeRestrictions.minHeight
      ) {
        this.sliderMax = Math.round(this.zoom * 100);
      }
      this.slider = Math.round(this.zoom * 100);
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    onSliderChange(val) {
      this.onZoom(val / 100);
    },
    decrement() {
      this.slider--;
      this.onSliderChange(this.slider);
    },
    increment() {
      this.slider++;
      this.onSliderChange(this.slider);
    },
  },
  async destroyed() {
    await this.turnOffCamera();
  },
};
</script>
<style lang="scss" scoped>
$black: #000000;
.upload-dialog {
  padding: 40px 40px 30px;
  position: relative;
  background-color: #ffffff;
  color: $black;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 499px) {
    padding: 20px 20px 16px;
    min-height: 100%;
    justify-content: center;
  }
  .dialog-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .label-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 44px;
      padding: 0 24px;

      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.04em;

      border-radius: 22.7053px;
      background-color: #e0e0e0;

      @include cursorPointer;
    }
    .button-margin {
      margin-left: 10px;
      @media screen and (max-width: 499px) {
        margin-left: 0;
        margin-top: 12px;
      }
    }
    #cameraFile {
      display: none;
    }
    .camera-button {
      height: 72px;
      width: 72px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: #cf1019;
      border-radius: 100%;
      @include cursorPointer;
      .material-icons {
        color: #ffffff;
        font-size: 38px;
      }
    }
    .mobile-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 44px;
      border-radius: 22.7053px;
      padding: 0 24px;
      color: #ffffff;
      background-color: #cf1019;
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      @include cursorPointer;
      &.is-disabled {
        background-color: #e0e0e0;
        color: #aaaaaa;
      }
    }
  }
  .mobile-grid {
    @media screen and (max-width: 499px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  .close-icon {
    position: absolute;
    right: 13px;
    top: 13px;
    font-size: 30px;
    @include cursorPointer;
  }
  .dialog-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 12px;
    @media screen and (max-width: 499px) {
      margin-bottom: 24px;
    }
  }
  .dialog-text {
    min-height: 52px;
    max-width: 352px;
    @media screen and (max-width: 499px) {
      max-width: 304px;
    }
  }
  .dialog-terms {
    margin-top: 10px;
    .terms-checkbox {
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
      @include cursorPointer;
      .mem-checkbox {
        margin-right: 12px;
      }
    }
    .terms-subline {
      margin-top: 20px;
      margin-bottom: 22px;
      margin-left: 32px;
      a {
        color: #7d7d7d;
        text-decoration: none;
        text-transform: lowercase;
      }
    }
    .terms-button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .file-drop-zone {
    display: grid;
    grid-template-columns: 1fr;
    height: 400px;
    background-color: #f2f2f2;
    margin-top: 20px;
    margin-bottom: 54px;
    padding: 18px;
    border-radius: 4px;
    @media screen and (max-width: 499px) {
      height: 265px;
      padding: 12px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .drop-state {
      border-radius: inherit;
      border: 1.5px dashed #bbbbbb;
      box-sizing: border-box;
      background-image: url("../../../assets/person_placeholder.svg");
      background-position: center;
      position: relative;
      @media screen and (max-width: 499px) {
        background-position: bottom;
      }
      .material-icons {
        --icon-size: 46px;
        position: absolute;
        color: #bbbbbb;
        top: calc(50% - var(--icon-size) / 2 - 10px);
        left: calc(50% - var(--icon-size) / 2);
        font-size: var(--icon-size);
        pointer-events: none;
        @media screen and (max-width: 499px) {
          top: calc(50% - var(--icon-size) / 2 + 6px);
        }
      }
      input {
        display: none;
      }
      label {
        height: 100%;
        display: flex;
      }
    }
  }
  .drop-shadow {
    background-color: #4f4f4f;
  }
  .cropper {
    height: 400px;
    // width: 510px;
    // width: 100%;
    margin-top: 24px;
    background: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    @media screen and (max-width: 499px) {
      height: 420px;
      margin-top: 16px;
      width: 100%;
    }
  }
  .cropper-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 375px;
    width: 100%;
    margin: 30px auto 40px;
    @media screen and (max-width: 499px) {
      max-width: 260px;
      // margin-top: 38px;
      margin-bottom: 24px;
    }
    .slider-control {
      color: #000000;
      @include cursorPointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .cropper-slider {
      // margin-left: 5px;
      // margin-right: 5px;
      ::v-deep {
        .v-input__slot {
          margin-bottom: 0;
        }
        .v-messages {
          display: none;
        }
        .v-slider__thumb {
          border: 1px solid;
          border-color: #dddddd !important;
        }
        .v-slider__track-background,
        .v-slider__track-fill {
          border-radius: 2px;
        }
      }
    }
  }
  #video {
    margin-top: 20px;
    margin-bottom: 26px;
  }
  .file-processing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 62px;
    padding-bottom: 94px;
    @media screen and (max-width: 499px) {
      padding-top: 0;
      padding-bottom: 4px;
    }
    .processing-text {
      max-width: 282px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin-top: 36px;
      @media screen and (max-width: 499px) {
        margin-top: 22px;
      }
    }
  }
  .dialog-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    .warning-icon {
    }
    .error-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 142px;
      max-width: 280px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      @media screen and (max-width: 499px) {
        min-height: auto;
        margin-top: 32px;
        margin-bottom: 22px;
      }
    }
  }
}
</style>